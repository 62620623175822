exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-news-jsx": () => import("./../../../src/pages/en/news.jsx" /* webpackChunkName: "component---src-pages-en-news-jsx" */),
  "component---src-pages-en-speakers-jsx": () => import("./../../../src/pages/en/speakers.jsx" /* webpackChunkName: "component---src-pages-en-speakers-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-speakers-jsx": () => import("./../../../src/pages/speakers.jsx" /* webpackChunkName: "component---src-pages-speakers-jsx" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-01-oleksandr-lytvynenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/01-oleksandr-lytvynenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-01-oleksandr-lytvynenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-02-henna-virkkunen-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/02-henna-virkkunen/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-02-henna-virkkunen-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-07-anton-demokhin-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/07-anton-demokhin/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-07-anton-demokhin-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-11-valeriya-ionan-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/11-valeriya-ionan/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-11-valeriya-ionan-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-12-juhan-lepassaar-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/12-juhan-lepassaar/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-12-juhan-lepassaar-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-13-nataliya-tkachuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/13-nataliya-tkachuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-13-nataliya-tkachuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-16-maksym-delembovskyi-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/16-maksym-delembovskyi/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-16-maksym-delembovskyi-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-18-dr-josef-schreofl-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/18-dr-josef-schreofl/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-18-dr-josef-schreofl-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-20-tetiana-lukyniuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/20-tetiana-lukyniuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-20-tetiana-lukyniuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-21-anna-bulakh-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/21-anna-bulakh/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-21-anna-bulakh-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-25-sergii-kulyk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/25-sergii-kulyk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-25-sergii-kulyk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-28-olena-stepura-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/28-olena-stepura/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-28-olena-stepura-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-29-ludmilla-huntsman-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/29-ludmilla-huntsman/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-29-ludmilla-huntsman-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-30-alexander-price-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/30-alexander-price/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-30-alexander-price-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-31-serhii-prokopenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/31-serhii-prokopenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-31-serhii-prokopenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-32-jaak-tarien-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/32-jaak-tarien/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-32-jaak-tarien-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-33-yevhen-derevianko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/33-yevhen-derevianko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-33-yevhen-derevianko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-38-mikko-hyppeonen-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/38-mikko-hyppeonen/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-38-mikko-hyppeonen-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-39-ieva-ilves-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/39-ieva-ilves/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-39-ieva-ilves-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-40-volodymyr-pokatilov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/40-volodymyr-pokatilov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-40-volodymyr-pokatilov-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-42-tomas-petru-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/42-tomas-petru/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-42-tomas-petru-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-43-arthur-david-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/43-arthur-david/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-43-arthur-david-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-44-anna-bon-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/44-anna-bon/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-44-anna-bon-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-45-anastasiia-ostrovska-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/45-anastasiia-ostrovska/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-45-anastasiia-ostrovska-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-46-tonu-greunberg-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/46-tonu-greunberg/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-46-tonu-greunberg-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-48-tanel-sepp-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/48-tanel-sepp/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-48-tanel-sepp-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-49-luca-tagliaretti-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/49-luca-tagliaretti/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-49-luca-tagliaretti-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-50-maryna-pavliuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/50-maryna-pavliuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-50-maryna-pavliuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-51-serhiy-chernenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/51-serhiy-chernenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-51-serhiy-chernenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-52-roman-sobol-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/52-roman-sobol/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-52-roman-sobol-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-53-george-papariga-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/53-george-papariga/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-53-george-papariga-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-54-roman-osadchuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/54-roman-osadchuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-54-roman-osadchuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-56-mykhailo-verych-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/56-mykhailo-verych/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-56-mykhailo-verych-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-57-serhii-khariuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/57-serhii-khariuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-57-serhii-khariuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-58-lauri-luht-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/58-lauri-luht/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-58-lauri-luht-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-59-oleksii-yankovski-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/59-oleksii-yankovski/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-59-oleksii-yankovski-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-68-varis-teivans-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/68-varis-teivans/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-68-varis-teivans-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-69-alona-zhuzha-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/69-alona-zhuzha/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-69-alona-zhuzha-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-70-oksana-merezhko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/70-oksana-merezhko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-70-oksana-merezhko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-72-stanislav-lurie-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/72-stanislav-lurie/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-72-stanislav-lurie-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-74-andrii-kovalenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/74-andrii-kovalenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-74-andrii-kovalenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-75-valerii-kulyk-kulychenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/75-valerii-kulyk-kulychenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-75-valerii-kulyk-kulychenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-80-arthur-bulyhin-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/80-arthur-bulyhin/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-80-arthur-bulyhin-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-82-ihor-malchenyuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/82-ihor-malchenyuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-82-ihor-malchenyuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-84-roman-proskurovskyi-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/84-roman-proskurovskyi/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-84-roman-proskurovskyi-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-86-nikita-veselkov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/86-nikita-veselkov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-86-nikita-veselkov-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-90-maria-shevchuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/90-maria-shevchuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-90-maria-shevchuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-91-stanislav-petrov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/en/91-stanislav-petrov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-en-91-stanislav-petrov-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-01-oleksandr-lytvynenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/01-oleksandr-lytvynenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-01-oleksandr-lytvynenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-02-henna-virkkunen-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/02-henna-virkkunen/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-02-henna-virkkunen-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-03-volodymyr-karastelov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/03-volodymyr-karastelov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-03-volodymyr-karastelov-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-04-mart-noorma-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/04-mart-noorma/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-04-mart-noorma-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-05-serhii-demediuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/05-serhii-demediuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-05-serhii-demediuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-07-anton-demokhin-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/07-anton-demokhin/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-07-anton-demokhin-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-11-valeriya-ionan-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/11-valeriya-ionan/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-11-valeriya-ionan-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-12-juhan-lepassaar-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/12-juhan-lepassaar/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-12-juhan-lepassaar-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-13-nataliya-tkachuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/13-nataliya-tkachuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-13-nataliya-tkachuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-16-maksym-delembovskyi-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/16-maksym-delembovskyi/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-16-maksym-delembovskyi-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-20-tetiana-lukyniuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/20-tetiana-lukyniuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-20-tetiana-lukyniuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-21-anna-bulakh-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/21-anna-bulakh/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-21-anna-bulakh-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-23-mr-maciej-stadejek-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/23-mr-maciej-stadejek/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-23-mr-maciej-stadejek-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-24-alexander-martin-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/24-alexander-martin/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-24-alexander-martin-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-25-sergii-kulyk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/25-sergii-kulyk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-25-sergii-kulyk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-28-olena-stepura-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/28-olena-stepura/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-28-olena-stepura-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-29-ludmilla-huntsman-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/29-ludmilla-huntsman/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-29-ludmilla-huntsman-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-30-alexander-price-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/30-alexander-price/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-30-alexander-price-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-31-serhii-prokopenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/31-serhii-prokopenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-31-serhii-prokopenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-32-jaak-tarien-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/32-jaak-tarien/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-32-jaak-tarien-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-33-yevhen-derevianko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/33-yevhen-derevianko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-33-yevhen-derevianko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-38-mikko-hyppeonen-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/38-mikko-hyppeonen/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-38-mikko-hyppeonen-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-39-ieva-ilves-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/39-ieva-ilves/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-39-ieva-ilves-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-40-volodymyr-pokatilov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/40-volodymyr-pokatilov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-40-volodymyr-pokatilov-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-42-tomas-petru-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/42-tomas-petru/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-42-tomas-petru-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-43-arthur-david-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/43-arthur-david/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-43-arthur-david-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-44-anna-bon-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/44-anna-bon/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-44-anna-bon-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-45-anastasiia-ostrovska-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/45-anastasiia-ostrovska/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-45-anastasiia-ostrovska-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-46-tonu-greunberg-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/46-tonu-greunberg/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-46-tonu-greunberg-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-48-tanel-sepp-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/48-tanel-sepp/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-48-tanel-sepp-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-49-luca-tagliaretti-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/49-luca-tagliaretti/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-49-luca-tagliaretti-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-50-maryna-pavliuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/50-maryna-pavliuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-50-maryna-pavliuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-51-serhiy-chernenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/51-serhiy-chernenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-51-serhiy-chernenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-52-roman-sobol-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/52-roman-sobol/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-52-roman-sobol-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-53-george-papariga-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/53-george-papariga/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-53-george-papariga-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-54-roman-osadchuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/54-roman-osadchuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-54-roman-osadchuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-56-mykhailo-verych-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/56-mykhailo-verych/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-56-mykhailo-verych-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-57-serhii-khariuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/57-serhii-khariuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-57-serhii-khariuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-58-lauri-luht-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/58-lauri-luht/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-58-lauri-luht-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-59-oleksii-yankovski-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/59-oleksii-yankovski/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-59-oleksii-yankovski-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-68-varis-teivans-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/68-varis-teivans/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-68-varis-teivans-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-69-alona-zhuzha-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/69-alona-zhuzha/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-69-alona-zhuzha-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-70-oksana-merezhko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/70-oksana-merezhko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-70-oksana-merezhko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-72-stanislav-lurie-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/72-stanislav-lurie/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-72-stanislav-lurie-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-74-andrii-kovalenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/74-andrii-kovalenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-74-andrii-kovalenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-75-valerii-kulyk-kulychenko-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/75-valerii-kulyk-kulychenko/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-75-valerii-kulyk-kulychenko-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-80-arthur-bulyhin-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/80-arthur-bulyhin/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-80-arthur-bulyhin-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-82-ihor-malchenyuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/82-ihor-malchenyuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-82-ihor-malchenyuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-84-roman-proskurovskyi-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/84-roman-proskurovskyi/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-84-roman-proskurovskyi-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-86-nikita-veselkov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/86-nikita-veselkov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-86-nikita-veselkov-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-87-oleksandr-korystin-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/87-oleksandr-korystin/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-87-oleksandr-korystin-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-90-maria-shevchuk-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/90-maria-shevchuk/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-90-maria-shevchuk-index-md" */),
  "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-91-stanislav-petrov-index-md": () => import("./../../../src/templates/speaker.jsx?__contentFilePath=/opt/build/repo/src/content/speakers/uk/91-stanislav-petrov/index.md" /* webpackChunkName: "component---src-templates-speaker-jsx-content-file-path-opt-build-repo-src-content-speakers-uk-91-stanislav-petrov-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-en-call-for-papers-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/en/call-for-papers/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-en-call-for-papers-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-en-privacy-policy-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/en/privacy-policy/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-en-privacy-policy-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-uk-call-for-papers-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/uk/call-for-papers/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-uk-call-for-papers-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-uk-privacy-policy-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/uk/privacy-policy/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-opt-build-repo-src-content-text-pages-uk-privacy-policy-index-md" */)
}

