import React from 'react';
import Link from '../components/Link';
import LocaleSwitch from '../components/LocaleSwitch';
import MenuButton from '../components/MenuButton';
import LogoSrc from '../images/logo.svg';
import useTranslation from '../hooks/useTranslation';
import useUrlLocalization from '../hooks/useUrlLocalization';
import { useLayoutState, useLayoutActions } from '../layout/Context';
import NavigationSidebar from './NavigationSidebar';

const Header = ({ path }) => {
  const { menuOpen, locale, isMediumScreen } = useLayoutState();
  const { toggleMenu } = useLayoutActions();
  const { t } = useTranslation('navigation', locale);
  const { url } = useUrlLocalization(locale);

  return (
    <>
      <header className="bg-black text-white fixed w-full left-0 top-0 right-0 z-20">
        <div className="container-lg flex items-center justify-between h-24 relative">
          <Link to={url('/')} className="block no-underline">
            <img src={LogoSrc} alt="Logo" className="h-10 lg:h-14" />
          </Link>
          {!isMediumScreen && (
            <nav className="hidden lg:flex py-2 rounded gap-x-8 uppercase">
              <Link to={`${url('/#agenda')}`} className="typo-intro text-green-400 no-underline">
                {t('Agenda')}
              </Link>
              <Link to={`${url('/speakers')}`} className="typo-intro text-green-400 no-underline">
                {t('Speakers')}
              </Link>
              <Link to={`${url('/news')}`} className="typo-intro text-green-400 no-underline">
                {t('News')}
              </Link>
              <Link to={`${url('/#contacts')}`} className="typo-intro text-green-400 no-underline">
                {t('Contacts')}
              </Link>
              <Link to="https://cyberforumkyiv.org/2024/" className="typo-intro text-green-400 no-underline">
                {t('KICRF – 2024')}
              </Link>
            </nav>
          )}
          {isMediumScreen && (
            <div className="lg:hidden">
              <MenuButton
                onClick={toggleMenu}
                isActive={menuOpen}
              />
            </div>
          )}
          <div className="absolute top-0 right-4">
            <LocaleSwitch locale={locale} path={path} />
          </div>
          {isMediumScreen && (
            <NavigationSidebar />
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
